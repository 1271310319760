import { useParams } from "react-router-dom";
import React from 'react';
import Parser from 'html-react-parser';
import { fetchCurrentCharacter } from '../../../reducers/currentCharacter'
import { useSelector, useDispatch } from 'react-redux'

const SingleCharacter = () => {
    const params = useParams();
    const currentCharacter = useSelector((state) => state.currentCharacter.value)
    const dispatch = useDispatch()

    React.useEffect(() => {
        async function fetchCharacters() {
            const res = await fetch(
                `https://finalfantasyguide.com/wp-json/react/v1/characters?character=` + params.characterSlug + "&game=" + params.slug
            );
            const json = await res.json();
            dispatch(fetchCurrentCharacter(json))
        }
        fetchCharacters(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const getCurrentCharacter = currentCharacter.payload

    function GetTitle() {
        if (!getCurrentCharacter) return false
        return getCurrentCharacter.parentCharacter.post_title
    }

    function GetContent() {
        if (!getCurrentCharacter) return false
        return Parser(getCurrentCharacter.currentCharacter.post_content ?? "No Content")
    }

    function GetGallery() {
        if (!getCurrentCharacter) return false
        if (!getCurrentCharacter.currentCharacter.fields.gallery) return false
        return (
            <div className="p-4 grid grid-cols-2 md:grid-cols-4 gap-4">
                { getCurrentCharacter.currentCharacter.fields.gallery.map(function(object, i){
                    return ( <div className="flex justify-center" key={i}>
                        <img src={object.sizes.medium} alt={getCurrentCharacter.parentCharacter.post_title}/>
                    </div> )
                })
            }
            </div>
        )
    }

    return (
        <section>
            <span className="block text-2xl bold mb-4 border-b pb-2">
                <GetTitle />
            </span>
            <div className="prose">
                <GetContent />
            </div>
            <GetGallery />
        </section>
    )
};
  
  export default SingleCharacter;