

export async function GetSingleCharacter (params) {
    const res = await fetch(
        `https://finalfantasyguide.com/wp-json/react/v1/characters?character=` + params.characterSlug + "&game=" + params.slug
    );
    return await res.json();
}
    

export async function GetCharacters (params) {
    const res = await fetch(
        `https://finalfantasyguide.com/wp-json/react/v1/characters?game=` + params.slug
    );
    return await res.json();
}
    