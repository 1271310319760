import { useParams } from "react-router-dom";
import React from 'react';
// import {  NavLink } from "react-router-dom";
import Parser from 'html-react-parser';

const NewsArticle = (props) => {


    const params = useParams();
    const [news, setNews] = React.useState([]);
    const [isLoading, setLoading] = React.useState([]);

    React.useEffect(() => {
        async function fetchData() {
            const res = await fetch(
                `https://finalfantasyguide.com/wp-json/react/v1/news?slug=` + params.slug
            );
            const json = await res.json();
            console.log(isLoading)
            setNews(json);
            setLoading(true);
        }
        fetchData(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <section>
            <div className="relative py-44 lg:py-72">
                <div className="absolute w-full h-full absolute left-0 top-0 overflow-hidden bg-cover bg-center"  style={{backgroundImage: news.currentNewsArticle ? `url("${news.currentNewsArticle.fields.featured_image.url}")` :false}}></div>
            </div>
            <div className="container mx-auto my-16 content">
                <h1 className="block text-2xl bold mb-4 border-b pb-2">
                    { news.currentNewsArticle ? news.currentNewsArticle.news[0].post_title : false }
                </h1>
                { news.currentNewsArticle ? 
                    <div className="prose max-w-full my-8">
                        {Parser(news.currentNewsArticle ? news.currentNewsArticle.news[0].post_content : 'No Content')}
                    </div>
                    : false
                }
            </div>
        </section>
    )
  };
  
  export default NewsArticle;