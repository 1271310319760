// import {writeJsonFile} from 'write-json-file';

export async function GetSingleGame (params) {
    const res = await fetch(
        `https://finalfantasyguide.com/wp-json/react/v1/games?slug=` + params.slug
    );
    return await res.json();
}
    
export async function GetGames () {

    try {
        const res = await fetch('https://finalfantasyguide.com/wp-content/themes/ffg/cache/games/getGames.json')
        return  res.json()
    } catch (error) {
        const res = await fetch(
            `https://finalfantasyguide.com/wp-json/react/v1/games?main=true`
        );
        return  res.json()
      }
}
    