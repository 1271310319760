import React from 'react';
import logo from './logo.svg';
import Navigation from "./components/shared/navigation/navigation";
import Footer from "./components/shared/footer/footer";
import './App.css';

function App() {
  return (
    <div className="App bg-gray-100">
      <Navigation />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
