import React from 'react';
import { useParams } from "react-router-dom";
import Parser from 'html-react-parser';
import { useSelector, useDispatch } from 'react-redux'
import { fetchCurrentGame } from '../../reducers/currentGame'
import { fetchChangePage } from '../../reducers/changePage'
import DetailBar from './detailBar';
import { BrowserRouter as Router, Route, Routes, NavLink } from "react-router-dom";
import Versions from './pages/versions';
import GamesDetails from './pages/details';
import Characters from './pages/Characters';
import SingleCharacter from './pages/singleCharacter';
import { GetSingleGame } from '../../api'

const GamesWrapper = () => {
    const params = useParams();
    const currentGame = useSelector((state) => state.currentGame.value)
    const changePage = useSelector((state) => state.fetchChangePage.value)
    const dispatch = useDispatch()

    React.useEffect(() => {
    dispatch(fetchChangePage(false))
    async function fetchData() {
            const json = await GetSingleGame(params)
            dispatch(fetchCurrentGame(json))
        }
        fetchData();
    }, [])

    const getCurrentGame = currentGame.payload
    const getChangePage = changePage.payload
      
    async function ChangePageStatusNow() {
        dispatch(fetchChangePage(true))
        setTimeout(function(){
            dispatch(fetchChangePage(false))
        }, 1000)
    }
        return (
            <section>
                <DetailBar games={getCurrentGame}/>
                <div className='flex items-start text-sm'>
                    <nav className="w-1/6 sidenav">
                        <ul>
                        <NavLink 
                            to={'/games/' + params.slug + '/details/'} 
                            className={() => (params['*'] == 'details' ? 'active' : '') + ' p-4 block border-b hover:bg-gray-700 hover:text-gray-100'}
                            >
                            <li>Overview</li>
                        </NavLink>
                        <NavLink 
                            to={'/games/' + params.slug + '/characters'} 
                            className={() => (params['*'] == 'characters' ? 'active' : '') + ' p-4 block border-b hover:bg-gray-700 hover:text-gray-100'}
                            onClick={(ChangePageStatusNow)}
                            >
                            <li>Characters</li>
                        </NavLink>
                        </ul>
                    </nav>
                    <div className='relative bg-gray-50 flex-1 p-4 min-h-screen border-l content'>
                    {/* <div className={`changePageOverlay bg-black transform transition ease-in-out  top-0 absolute left-0 w-full opacity-0 h-full ${getChangePage == false ? 'opacity-0' : 'z-20 opacity-100'}`}></div> */}
                        <Routes>
                            <Route path="details" element={<GamesDetails />} />
                            <Route exact path="characters" element={<Characters />} />
                            <Route exact path="versions" element={<Versions />} />
                            <Route exact path="character/:characterSlug/overview" element={<SingleCharacter />} />
                        </Routes>
                    </div>
                </div>
            </section>
        )
  };
  
  export default GamesWrapper;