import { BrowserRouter as Router, Route, Routes, NavLink } from "react-router-dom";
// import {  NavLink } from "react-router-dom";
import Home from '../../pages/Home';
import Games from '../../pages/Games';
// import News from '../../../pages/News';
import NewsArticle from '../../pages/NewsArticle';
import GamesWrapper from '../../games/wrapper';

const Navigation = () =>  {
    return (
      <div>
      <Router>
        <header className="py-4">
          <div className="logo">
            <NavLink to="/">Final Fantasy Guide</NavLink>
          </div>
          <nav>
            <ul className="my-0">
                {/* <NavLink to="/">
                <li>Home</li>
                </NavLink> */}
                {/* <NavLink to="/games">
                <li>Games</li>
                </NavLink> */}
            </ul>
          </nav>
        </header>
        <div className="min-h-screen">
          <Routes>
              <Route exact path="/" element={<Home />} />
               <Route exact path="/games/" element={<Games />} />
               <Route path="/games/:slug/*" element={<GamesWrapper />} />
               <Route exact path="/news/:slug" element={<NewsArticle />} />
          </Routes>
        </div>
      </Router>
      </div>
    );
  }

  export default Navigation;