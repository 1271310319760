import { configureStore } from '@reduxjs/toolkit'
import games from '../reducers/games'
import currentGame from '../reducers/currentGame'
import currentCharacter from '../reducers/currentCharacter'
import characters from '../reducers/characters'
import fetchChangePage from '../reducers/changePage'

export default configureStore({
  reducer: {
    games,
    currentGame,
    currentCharacter,
    characters,
    fetchChangePage,
  },
})