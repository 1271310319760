import React from 'react';
import {  NavLink, useParams } from "react-router-dom";
import Parser from 'html-react-parser';

const DetailBar = (props) => {
    const params = useParams();
    const games = props.games
    function GetTitle() {
        if (!games) return false;
        if (games.parentGame) return games.parentGame.game.post_title
        if (games.currentGame) return games.currentGame.game.post_title
    }
    function GetDeatils() {
        let details = ''
        if (!games) return false;
        if (games.currentGame.fields.release_information.system) details += `<span className='font-bold'>platform: </span> ${games.currentGame.fields.release_information.system}`
        if (games.currentGame.fields.release_information.region) details += `<span className='font-bold'>region: </span> ${games.currentGame.fields.release_information.region}`
        if (games.currentGame.fields.release_information.date) details += `<span className='font-bold'>release date: </span> ${games.currentGame.fields.release_information.date}` 
        return Parser(details)
    }
    
    return (
        <div className="p-4 w-full bg-gray-100 border-b sticky flex top-0 justify-between items-center">
            <GetTitle />
            <div className='uppercase text-sm flex space-x-4'>
                <GetDeatils />
                <NavLink to={'/games/' + params.slug + '/versions'}>
                    <svg className="h-5" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>
                </NavLink>
            </div>
        </div>
    )
  };
  
  export default DetailBar;