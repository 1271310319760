

import React from 'react';
import { useParams } from "react-router-dom";
import Parser from 'html-react-parser';
import { useSelector, useDispatch } from 'react-redux'
import { fetchCurrentGame } from '../../../reducers/currentGame'
import DetailBar from '../detailBar';
import { BrowserRouter as Router, Route, Routes, NavLink } from "react-router-dom";
import Versions from './versions';

const Details = () => {
    const params = useParams();
    const currentGame = useSelector((state) => state.currentGame.value)
    const dispatch = useDispatch()
    React.useEffect(() => {
    async function fetchData() {
        const res = await fetch(
            `https://finalfantasyguide.com/wp-json/react/v1/games?slug=` + params.slug
        );

        const json = await res.json();
        dispatch(fetchCurrentGame(json))
    }
    fetchData();
    }, [])
    const getCurrentGame = currentGame.payload
        return (
            <section>
            <span className="block text-2xl bold mb-4">
            {!getCurrentGame ? false : getCurrentGame.parentGame ? getCurrentGame.parentGame.game.post_title : getCurrentGame.currentGame.game.post_title} Overview
            </span>
            <div className="prose max-w-full">
                {!getCurrentGame ? false : Parser(getCurrentGame.currentGame.game.post_content ?  getCurrentGame.currentGame.game.post_content : 
                getCurrentGame.parentGame && getCurrentGame.parentGame.game.post_content ? getCurrentGame.parentGame.game.post_content : 'No content yet')}
            </div>
            </section>
        )
  };
  
  export default Details;