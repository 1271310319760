import React from 'react';
import {  NavLink } from "react-router-dom";

const Home = () => {

    const [news, setNews] = React.useState([]);

    React.useEffect(() => {
        async function fetchData() {
            // Call fetch as usual
            const res = await fetch(
                `https://finalfantasyguide.com/wp-json/react/v1/news`
            );

            // Pull out the data as usual
            const json = await res.json();
            // Save the posts into state
            setNews(json.map(c => c));
        }

        fetchData();
    }, []); // <-- we didn't pass a value. what do you think will happen?
    return (
        <section className='p-4'>
            <span className="block text-center text-xl bold mb-4">
                Whilst we get things ready, please enjoy the latest news!
            </span>


            <section className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    {news.map((newsArticle, key) => (
                        <NavLink to={'/news/' + newsArticle.post_name} key={key}>
                            <div className="shadow-2xl bg-gray-200 justify-end  flex  relative  h-64 overflow-hidden">
                                <div className="absolute bg-gray-200 w-full h-full absolute left-0 top-0  bg-cover bg-center">
                                    <img
                                        src={newsArticle.fields.featured_image.sizes.medium_large}
                                        alt="something" 
                                        className=" bg-gray-200 lazy object-cover h-full w-full" 
                                    />
                                </div>
                                <span className='text-xs p-2 self-end text-white bg-black opacity-80'>{ newsArticle.post_title }</span>
                            </div>
                        </NavLink>
                    ))}
                </section>


        </section>
    )
  };
  
  export default Home;