import React from 'react';
import {  NavLink } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { fetchGame } from '../../reducers/games'
import { GetGames } from '../../api'

const Games = () => {
    const games = useSelector((state) => state.games.value)
    const dispatch = useDispatch()
    
    React.useEffect(() => {
        async function fetchData() {
            const json = await GetGames()
            dispatch(fetchGame(json.map(c => c)))
        }
        fetchData();
    }, [])
    
    const getGames = games.payload

    function GetGameCard(data) {
        const game = data.game
        if (!game) return false
        const style = !game.fields.featured_image ? '' : game.fields.featured_image.sizes.medium 
        return (
            <div className="bg-white h-64 bg-cover bg-center flex flex-col w-full text-center relative items-center border-1 shadow-xl justify-end text-xl leading-2" style={{backgroundImage: `url(${style})`}}>
                    <div className="bg-black bg-opacity-50 text-white w-full py-2">
                        {!game.post_title ? 'Loading' : game.post_title }
                    </div>
            </div>
        )
    }
    return (
        <section className="p-4 grid grid-cols-4 gap-4">
        { !getGames ? false : getGames.map((game, key) => (
            <NavLink to={'/games/' + game.post_name + '/details'} key={key}>
                <GetGameCard game={game} />
            </NavLink>
        ))}
    </section>
    )
  };
  
  export default Games;