import React from 'react';
import { useParams } from "react-router-dom";
import {  NavLink } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { fetchCurrentGame } from '../../../reducers/currentGame'



const Versions = () => {
    const params = useParams();
    const currentGame = useSelector((state) => state.currentGame.value)
    
    const dispatch = useDispatch()
    React.useEffect(() => {
    async function fetchData() {
        const res = await fetch(
            `https://finalfantasyguide.com/wp-json/react/v1/games?slug=` + params.slug
        );

        const json = await res.json();
        dispatch(fetchCurrentGame(json))
    }
    fetchData();
    }, [])
    
    const getCurrentGame = currentGame.payload
        return (
            <section className="p-4 grid grid-cols-4 gap-4">
                {getCurrentGame.gameVariations.game.map(game => (
                <NavLink to={'/games/' + game.post_name + '/details'} key={game.key}>
                    <div className="flex text-center items-center justify-center bg-red-500 h-32">
                        {!game.post_title ? 'Loading' : game.post_title }
                    </div>
                </NavLink>
            ))}
            </section>
        )
  };
  
  export default Versions;

 