import React from 'react';
import { useParams } from "react-router-dom";
import {  NavLink } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { fetchCurrentGame } from '../../../reducers/currentGame'
import { getCharacter } from '../../../reducers/characters'
import { GetSingleGame, GetCharacters } from '../../../api'

const Characters = () => {
    const params = useParams();
    const currentGame = useSelector((state) => state.currentGame.value)
    const characters = useSelector((state) => state.characters.value)
    const dispatch = useDispatch()

    React.useEffect(() => {
        async function fetchGame() {
            const json = await GetSingleGame(params)
            dispatch(fetchCurrentGame(json))
        }
        fetchGame(); 
        async function fetchCharacters() {
            const json = await GetCharacters(params)
            dispatch(getCharacter(json))
            
        }
        fetchCharacters(); 
    }, []);

    const getCharacters = characters.payload
    const games = currentGame.payload

    function GetTitle() {
        if (!games) return false;
        if (games.parentGame) return games.parentGame.game.post_title
        if (games.currentGame) return games.currentGame.game.post_title
    }

    function GetCharacterCard(data) {
        const character = data.character
        // console.log(character)
        if (!character) return false
        const style = character.currentCharacter.fields && character.currentCharacter.fields.gallery ? character.currentCharacter.fields.gallery[0].sizes.medium  : '' 
        return (
            <div className="bg-white h-64 bg-cover bg-center flex flex-col w-full text-center relative items-center border-1 shadow-xl justify-end text-xl leading-2" style={{backgroundImage: `url(${style})`}}>
                <div className="bg-black bg-opacity-50 text-white w-full py-2">
                    {!character.parentCharacter[0].post_title ? 'Loading' : character.parentCharacter[0].post_title }
                </div>
            </div>
        )
    }
    
    return (
        <section>
            <span className="block text-2xl bold mb-4">
                <GetTitle /> Characters
            </span>
            <section className="grid grid-cols-3 gap-4">
                {!getCharacters ? false : getCharacters.map(character => (
                    <NavLink to={'/games/' + params.slug + '/character/' + character.currentCharacter.post_name + '/overview'} key={character.currentCharacter.key}>
                        <GetCharacterCard character={character} />
                    </NavLink>
                ))}
            </section>
        </section>
    )
  };
  
  export default Characters;